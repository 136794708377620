var App = {
    init: function () {
        var self = this;
        this.initSwipers();
        this.initSmoothscroll();
        this.initCollapsibles();
        this.initQuoteStyles();

        $(".header-slide .header-slide-content a").on('click', function (e) {
            e.preventDefault();
            var win = window.open(e.target.href, '_blank');
            win.focus();
        });

        // Set donate links
        $('.js-set-donate-link').on('click', function (e) {
            var link = $(e.target).data('setDonateLink');
            var _link = link;
            var regNum = new RegExp('[0-9]');
            if ($(e.target).hasClass("js-set-last")) {
                $("#user-donation-input").removeClass("d-none");
                $("#user-donation-input input").val("");
                $("#user-donation-input input").on("keydown", function (e) {
                    if (!regNum.test(e.key) && e.key.toLowerCase() != "tab" && e.key.toLowerCase() != "backspace") {
                        e.preventDefault();
                        $(e.target).val().slice(0, $(e.target).val().length - 1);
                        return;
                    }
                });
                $("#user-donation-input input").on("keyup", function (e) {
                    $('a.js-donate-link').attr('href', link + "?t=M&a=" + $("#user-donation-input input").val());
                });
            } else {
                $("#user-donation-input").addClass("d-none");
            }
            $('a.js-donate-link').attr('href', link);
        });

        // Toggle mobile menu
        $('.js-toggle-mobile-menu').on('click', function (e) {
            e.preventDefault();
            $('.site-header').toggleClass('mobile-menu-is-open');
            $(".site-nav-primary").removeClass("open");
            $(".site-nav-primary").has(".site-nav-sub").children("a").toggleClass("dropdown-toggle").append("<span>▼</span>");

            $(".mobile-menu-is-open .site-nav-primary > a.dropdown-toggle span").on("click", function (e) {
                if (!$(e.currentTarget.parentNode.parentNode).hasClass("open") && $(e.currentTarget.parentNode).siblings(".site-nav-sub").length) {
                    e.preventDefault();
                    $(".mobile-menu-is-open .site-nav-primary").removeClass("open");
                    $(e.currentTarget.parentNode.parentNode).addClass("open");
                } else if ($(e.currentTarget.parentNode.parentNode).hasClass("open") && $(e.currentTarget.parentNode).siblings(".site-nav-sub").length) {
                    e.preventDefault();
                    $(e.currentTarget.parentNode.parentNode).removeClass("open");
                }
            });
        });

        // Toggle search menu
        $('.js-toggle-search').on('click', function (e) {
            e.preventDefault();
            $('.search-container').toggleClass('is-active');
            if ($('.search-container').hasClass('is-active')) {
                $('#SearchTerm').focus();
            }
        });

        // Activate / keep cookiebar disabled
        if (!Cookies.get('has_disabled_cookiebar')) {
            $('#cookies_banner').show();
        } else {
            Cookies.remove('has_disabled_cookiebar');
            var cname = "has_disabled_cookiebar";
            var cvalue = true;
            var exdays = 356;

            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; domain=.aidsfonds.nl;" + expires + ";path=/";
        }
        $('.js-close-cookiebar').on('click', function (e) {
            e.preventDefault();
            $('#cookies_banner').hide();

            var cname = ["has_disabled_cookiebar", "cookie_advertisement", "cookie_other"];
            var cvalue = true;
            var exdays = 356;

            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            for (var i = 0; i < cname.length; i++) {
                document.cookie = cname[i] + "=" + cvalue + "; domain=.aidsfonds.nl;" + expires + ";path=/";
            }
            location.reload();
        });
        $('#saveCookies').on('click', function (e) {
            $('#cookies_banner').hide();

            var cname = "has_disabled_cookiebar";
            var cvalue = true;
            var exdays = 356;

            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; domain=.aidsfonds.nl;" + expires + ";path=/";

            if ($("#advertisementCookies").get(0).checked) {
                var cname = "cookie_advertisement";
                var cvalue = true;
                var exdays = 356;

                var d = new Date();
                d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + "; domain=.aidsfonds.nl;" + expires + ";path=/";
            } else {
                Cookies.remove('cookie_advertisement');
                document.cookie = "cookie_advertisement= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            }

            if ($("#otherCookies").get(0).checked) {
                var cname = "cookie_other";
                var cvalue = true;
                var exdays = 356;

                var d = new Date();
                d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + "; domain=.aidsfonds.nl;" + expires + ";path=/";
            } else {
                Cookies.remove('cookie_other');
                document.cookie = "cookie_other= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            }
            location.reload();
        });

        // Fixed header check
        $(window).on('scroll', function () {
            self.checkScroll();
            App.checkScroll()
        });
        this.checkScroll();
    },

    checkScroll: function () {
        if (window.pageYOffset > 5) {
            $(".site-header").addClass("is-scrolled");
        } else {
            $(".site-header").removeClass("is-scrolled");
        }
    },

    initSwipers: function () {
        this.swipers = [];
        var swiperContainers = document.querySelectorAll('.js-swiper');
        for (var i = 0; i < swiperContainers.length; i++) {
            var settings = {
                headerCarousel: {
                    effect: 'fade',
                    allowTouchMove: false,
                    speed: 600,
                    autoplay: {
                        delay: 5000
                    }
                }
            }

            var swiper = new Swiper(swiperContainers[i], settings.headerCarousel);
            this.swipers.push(swiper);
        }
    },

    initSmoothscroll: function () {
        var smoothscroll = new SmoothScroll();

        var smoothScrollWithoutHash = function (selector, settings) {
	        /**
	         * If smooth scroll element clicked, animate scroll
	         */
            var clickHandler = function (event) {
                var toggle = event.target.closest(selector);
                //console.log(toggle);
                if (!toggle || toggle.tagName.toLowerCase() !== 'a') return;
                //console.log(toggle.hash);
                var anchor = document.querySelector(toggle.hash);
                if (!anchor) return;

                event.preventDefault(); // Prevent default click event
                smoothscroll.animateScroll(anchor, toggle, settings || {}); // Animate scroll
            };

            window.addEventListener('click', clickHandler.bind(this), false);
        };

        // Run our function
        smoothScrollWithoutHash('a[href*="#"]', {
            // Selectors
            ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid CSS selector)
            header: null, // Selector for fixed headers (must be a valid CSS selector)

            // Speed & Easing
            speed: 500, // Integer. How fast to complete the scroll in milliseconds
            offset: 60, // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
            easing: 'easeInOutCubic', // Easing pattern to use
            customEasing: function (time) {

                // Function. Custom easing pattern
                // If this is set to anything other than null, will override the easing option above

                // return <your formulate with time as a multiplier>

                // Example: easeInOut Quad
                return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time;

            },

            // Callback API
            before: function (anchor, toggle) { console.log(anchor, toggle); }, // Callback to run before scroll
            after: function (anchor, toggle) { console.log(anchor, toggle); } // Callback to run after scroll
        });

        this.smoothscroll = smoothscroll;
    },

    initCollapsibles: function () {
        var collapsibleToggles = document.querySelectorAll('.js-collapsible-toggle');
        for (var i = 0; i < collapsibleToggles.length; i++) {
            var toggle = collapsibleToggles[i];

            // On click
            toggle.addEventListener('click', function () {
                var wrapper = this.parentNode;
                var accordionElements = $(".nc-collapsible .nc-collapsible-item.is-active");

                // Toggle is-active class
                if (wrapper.classList.contains('is-active')) {
                    wrapper.classList.remove('is-active');
                } else {
                    accordionElements.removeClass('is-active');
                    wrapper.classList.add('is-active');
                }
            });
        }
    },

    initQuoteStyles: function () {
        // add quotations ("") in quote
        var quotes = document.querySelectorAll(".nc-quote-block .quote-wrapper .quote");
        quotes.forEach(function (el) {
            var paragraphs = el.querySelectorAll("p");
            paragraphs[0].textContent = "\"" + paragraphs[0].textContent;
            paragraphs[paragraphs.length-1].textContent = paragraphs[paragraphs.length-1].textContent + "\"";
        });

        // make thumbs square
        //var setSize = function () {
        //    var thumbs = document.querySelectorAll(".nc-quote-block .thumbnail-wrapper .thumbnail");
        //    thumbs.forEach(function (el) {
        //        var image = el.querySelector("img");
        //        var imgHeight = image.height;
        //        var visibleImage = el.querySelector(".visible");
        //        visibleImage.style.height = imgHeight + "px";
        //        visibleImage.style.width = imgHeight + "px";
        //    });
        //};

        //setSize();
        //window.addEventListener("resize", setSize, false);
    }
};
App.init();